// url to fetch api's
export const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;

export const API_ENDPOINTS_URLS = {
    getGroupInfo: API_GATEWAY_URL + "group/details",
    updateGroup: API_GATEWAY_URL + "group/update",
    getTemplates: API_GATEWAY_URL + "template/listAll",
    getDripMessages: API_GATEWAY_URL + "template/listDripMessages",
    updateTemplate: API_GATEWAY_URL + "template/update",
    updateDrip: API_GATEWAY_URL + "template/update_drip_message",
    addDrip: API_GATEWAY_URL + "template/add_drip_message",
    deleteDrip: API_GATEWAY_URL + "drip_message/delete",
    deleteTemplate: API_GATEWAY_URL + "template/delete",
    validateIntouch: API_GATEWAY_URL + "intouch/validate_credentials",
    saveIntouchSettings: API_GATEWAY_URL + "intouch/save_intouch_settings",
    getIntouchSettings: API_GATEWAY_URL + "intouch/get_intouch_settings",
    addUserTemplate: API_GATEWAY_URL + "user_template/add",
    getUserTemplates: API_GATEWAY_URL + "user_template/listAll",
    deleteUserTemplate: API_GATEWAY_URL + "user_template/delete",
    userTemplateDetails: API_GATEWAY_URL + "user_template/details",
    updateUserTemplate: API_GATEWAY_URL + "user_template/update",
    userDripMessages: API_GATEWAY_URL + "user_template/listDripMessages",
    updateUserDrip: API_GATEWAY_URL + "user_template/update_drip_message",
    addUserDrip: API_GATEWAY_URL + "user_template/add_drip_message",
    deleteUserDrip: API_GATEWAY_URL + "user_drip_message/delete",
    getContacts: API_GATEWAY_URL + "contact/listAll",
    getActiveContactsCount: API_GATEWAY_URL + "contact/get_contact_active_numbers",
    getDropdownGroupsListCounts: API_GATEWAY_URL + "group/list_all_with_count",
    getDropdownGroupsList: API_GATEWAY_URL + "dropdown/group/list_all",
    getContactDetails: API_GATEWAY_URL + "contact/get_contact_details",
    getContactGroups: API_GATEWAY_URL + "contact/get_contact_groups",
    updateContact: API_GATEWAY_URL + "contact/update",
    createKeyword: API_GATEWAY_URL + "keywords/add",
    getKeywords: API_GATEWAY_URL + "keywords/listAll",
    getKeywordInfo: API_GATEWAY_URL + "keywords/keyword_info",
    updateKeywordFollowup: API_GATEWAY_URL + "keywords/update_followup_message",
    addKeywordFollowup: API_GATEWAY_URL + "keywords/add_followup_message",
    deleteKeywordFollowup: API_GATEWAY_URL + "keywords/delete_followup",
    getEditKeywordInfo: API_GATEWAY_URL + "keywords/edit_keyword",
    getKeywordsFollowups: API_GATEWAY_URL + "keywords/keywords_followups",
    updateKeyword: API_GATEWAY_URL + "keywords/update_keyword",
    getNotificationSettings: API_GATEWAY_URL + "users/get_notification_settings",
    updateReplyNotification: API_GATEWAY_URL + "users/update_notification_settings",
    getManagerFolderList: API_GATEWAY_URL + "folder/list_all_by_user",
    getManagerCategoriesList: API_GATEWAY_URL + "category/list_all_by_user",
    getManagerTemplates: API_GATEWAY_URL + "template/list_all_by_user",
    copyContacts: API_GATEWAY_URL + "contact/copy_to_group",
    moveContacts: API_GATEWAY_URL + "contact/move_to_group",
    removeContacts: API_GATEWAY_URL + "contact/remove_from_all_groups",
    unsubContacts: API_GATEWAY_URL + "contact/bulk_unsubscribe",
    getUnsubscribers: API_GATEWAY_URL + "contact/listAllUnsubscribed",
    getRechargeSettings: API_GATEWAY_URL + "billing/get_users_recharge_settings",
    getOrderHistory: API_GATEWAY_URL + "billing/get_order_history",
    getCardsOnFile: API_GATEWAY_URL + "billing/get_card_details",
    updateRechargeSettings: API_GATEWAY_URL + "billing/update_user_recharge_settings",
    chargeCard: API_GATEWAY_URL + "billing/charge_my_card",
    addNewCreditCard: API_GATEWAY_URL + "billing/add_new_card",
    getVoicemailSettings: API_GATEWAY_URL + "profile/get_voicemail_settings",
    sendCode: API_GATEWAY_URL + "profile/callforward_send_code",
    callforwardVerifyCode: API_GATEWAY_URL + "profile/callforward_verify_code",
    addVoicemailSettings: API_GATEWAY_URL + "profile/add_voicemail_settings",
    updateVoicemailSettings: API_GATEWAY_URL + "profile/update_voicemail_settings",
    getSubscriptionDetail: API_GATEWAY_URL + "profile/get_subscription",
    getProfileDetail: API_GATEWAY_URL + "profile/get_profile_details",
    updateGenericResponse: API_GATEWAY_URL + "profile/update_generic_response",
    updateTimezone: API_GATEWAY_URL + "profile/update_timezone",
    getVoicemails: API_GATEWAY_URL + "profile/list_all_voicemails",
    getForwardedCalls: API_GATEWAY_URL + "profile/list_all_forwarded",
    deleteVoicemail: API_GATEWAY_URL + "profile/delete_voicemail",
    getAllContacts: API_GATEWAY_URL + "campaign/listAllContacts",
    createNormalCampaign: API_GATEWAY_URL + "campaign/create_normal_campaign",
    getAllResponses: API_GATEWAY_URL + "keywords/get_all_responses",
    getScheduledCampaigns: API_GATEWAY_URL + "campaign/get_all_scheduled",
    getSentCampaigns: API_GATEWAY_URL + "campaign/get_all_sent",
    getAllInvoices: API_GATEWAY_URL + "billing/get_all_invoices",
    getCommunication: API_GATEWAY_URL + "keywords/get_communication",
    sendIndividualMessage: API_GATEWAY_URL + "campaign/send_individual_message",
    sendToSelected: API_GATEWAY_URL + "campaign/send_to_selected",
    deleteAllResponseMsg: API_GATEWAY_URL + "keywords/remove_from_all_response",
    setResponseNote: API_GATEWAY_URL + "keywords/update_response_note",
    updateStarStatus: API_GATEWAY_URL + "keywords/update_star_status",
    updateDefaultCard: API_GATEWAY_URL + "profile/set_default_card",
    getInvoiceDetails: API_GATEWAY_URL + "billing/get_invoice_details",
    createCampaign: API_GATEWAY_URL + "campaign/create_campaign",
    deleteCampaign: API_GATEWAY_URL + "campaign/delete_campaign",
    getCampaignDetails: API_GATEWAY_URL + "campaign/get_campaign_details",
    getCampaignSubscribers: API_GATEWAY_URL + "campaign/get_campaign_subscribers",
    getBirthdayDetails: API_GATEWAY_URL + "contact/birthday_details",
    updateBirthday: API_GATEWAY_URL + "contact/update_birthday",
    getIMSubscribers: API_GATEWAY_URL + "contact/get_im_user_messages",
    deleteIMMsg: API_GATEWAY_URL + "contact/delete_from_im_message",
    updateIMStarStatus: API_GATEWAY_URL + "contact/set_im_star_status",
    downloadunsubs: API_GATEWAY_URL + "contact/download_unsubscribers",
    downloadAllResponses: API_GATEWAY_URL + "keywords/download_all_responses",
    stopSubscribers: API_GATEWAY_URL + "campaign/remove_subscriber_from_campaign",
    getUsersList: API_GATEWAY_URL + "admin/get_users",
    searchUsers: API_GATEWAY_URL + "admin/search_users",
    downloadUsers: API_GATEWAY_URL + "admin/download_users",
    getUserDetails: API_GATEWAY_URL + "admin/get_user_details",
    updateUserDetails: API_GATEWAY_URL + "admin/update_user_details",
    changeNextBillDate: API_GATEWAY_URL + "admin/change_next_bill_date",
    getIpLocation: API_GATEWAY_URL + "admin/get_ip_location",
    updateBanner: API_GATEWAY_URL + "admin/update_banner",
    getDupliateInvoices: API_GATEWAY_URL + "admin/get_duplicate_invoices",
    GetA2PUsers: API_GATEWAY_URL + "admin/get_a2p_status",
    updateBirthdayMessage: API_GATEWAY_URL + "manager/update_birthdays",
    switchUser: API_GATEWAY_URL + "users/switch_account",
    getCampaignEvents: API_GATEWAY_URL + "calendar/get_campaign_events",
    getIndividualEvents: API_GATEWAY_URL + "calendar/get_individual_events",
    getKeywordDrips: API_GATEWAY_URL + "calendar/get_keyword_drip_events",
    getSpecialEvents: API_GATEWAY_URL + "calendar/get_special_events",
    getCampaignsDrips: API_GATEWAY_URL + "calendar/get_campaign_drip_events",
    getContactList: API_GATEWAY_URL + "contact/list_contacts",
    //updateBirthdayMessage: API_GATEWAY_URL + "manager/update_birthdays",
    uploadContactFile: API_GATEWAY_URL + 'contact/upload_contact_file',
    getUsers: API_GATEWAY_URL + "manager/get_users",
    searchManagerUsers: API_GATEWAY_URL + "manager/search_users",
    overdueUsers: API_GATEWAY_URL + "manager/get_overdue_users",
    downloadOverdueUsers: API_GATEWAY_URL + "manager/download_overdue_invoices",
    getBanner: API_GATEWAY_URL + "admin/get_banner",
    getProfileBanner: API_GATEWAY_URL + "profile/get_banner",
    getfunds: API_GATEWAY_URL + "profile/get_fund_details",
    getCampaignConIds: API_GATEWAY_URL + "campaign/get_campaign_conids",
    updateCampaign: API_GATEWAY_URL + "campaign/update_campaign_new",
    unsubscribeContactsViaFile: API_GATEWAY_URL + "contact/unsubscribe_contacts_by_file",
    createShortLink: API_GATEWAY_URL + "campaign/create_short_link",
    removeSelectedContacts: API_GATEWAY_URL + "contact/remove_group_relation",
    stopScheduled: API_GATEWAY_URL + "contact/stop_scheduled_message",
    getSwapRequest: API_GATEWAY_URL + "admin/get_swap_requests",
    getSwapDetails: API_GATEWAY_URL + "admin/swap_details",
    releaseNumber: API_GATEWAY_URL + "admin/release_numbers",
    manageNumbers: API_GATEWAY_URL + "profile/get_account_numbers",
    updatePrimaryNumber: API_GATEWAY_URL + "profile/update_primary_number",
    getNumberByAreaCode: API_GATEWAY_URL + "profile/get_numbers_by_area_code",
    sendSwapRequest: API_GATEWAY_URL + "profile/send_swap_request",
    processOverdue: API_GATEWAY_URL + "profile/process_overdue_invoice",
    updateViolation: API_GATEWAY_URL + "profile/update_violation_state",
    removeCrad: API_GATEWAY_URL + "profile/remove_credit_card",
    getShortLinks: API_GATEWAY_URL + "campaign/get_short_link",
    buyNumbers: API_GATEWAY_URL + "profile/buy_account_number",
    getDashboardData: API_GATEWAY_URL + 'admin/get_dashboard_data',
    updateDashboardData: API_GATEWAY_URL + 'admin/update_dashboard_segment',
    getCardDetails: API_GATEWAY_URL + "admin/get_card_onfile",
    getTwoFactorSetting: API_GATEWAY_URL + "profile/get_two_factor_settings",
    updateTwoFactorSetting: API_GATEWAY_URL + "profile/update_two_factor_settings",
    sendTwoFactorCode: API_GATEWAY_URL + "profile/send_two_factor_code_profile",
    verifyCode: API_GATEWAY_URL + "profile/verify_two_factor_code_profile",
    getGraphstatus: API_GATEWAY_URL + "admin/get_graph_stats",
    updateNotes: API_GATEWAY_URL + "admin/update_notes",
    sendTextMessage: API_GATEWAY_URL + "admin/send_test_message",
    getActiveUsersList: API_GATEWAY_URL + "admin/get_active_users",
    deleteKeyword: API_GATEWAY_URL + "keywords/delete_keyword",
    deleteKeywordFollowups: API_GATEWAY_URL + "keywords/delete_followups",
    getPendingUsers: API_GATEWAY_URL + "admin/get_pending_users",
    downlodPendingInv: API_GATEWAY_URL + "admin/download_pending_invoices",
    getVipeCloudLists: API_GATEWAY_URL + "contact/get_vipecloud_lists",
    getVipeCloudDetails: API_GATEWAY_URL + "contact/get_vipecloud_details",
    setVipeCloudDetails: API_GATEWAY_URL + "contact/set_vipecloud_details",
    exportVipeCloudContact: API_GATEWAY_URL + "contact/add_vipecloud_setting",
    getIntouchTriggerSettings: API_GATEWAY_URL + "intouch/get_intouch_trigger_settings",
    saveIntouchTriggerSettings: API_GATEWAY_URL + "intouch/save_intouch_trigger_settings",
    addVipeCloudTriggerSetting: API_GATEWAY_URL + "contact/add_vipecloud_trigger_setting",
    getCoregDetails: API_GATEWAY_URL + "contact/get_coreg_details",
    addCoregSetting: API_GATEWAY_URL + "contact/add_coreg_setting",
    validateFolderPassword: API_GATEWAY_URL + "folder/validate_password",
    checkExistingAccess: API_GATEWAY_URL + "folder/check_existing_access",
    getCustomFields: API_GATEWAY_URL + "contact/get_custom_fields",
    getMonthlySpendings: API_GATEWAY_URL + "admin/get_monthly_spendings",
    DeleteScheduleledCampaign: API_GATEWAY_URL + "campaigns/delete_scheduled_campaign",
    getSlackSetting: API_GATEWAY_URL + "slack/get_setting",
    addSlackSetting: API_GATEWAY_URL + "slack/add_setting",
    addSlackTrigger: API_GATEWAY_URL + "slack/add_trigger",
    getSlackTriggers: API_GATEWAY_URL + "slack/list_all_triggers",
    getTriggerDetails: API_GATEWAY_URL + "slack/get_trigger_details",
    updateTriggerDetails: API_GATEWAY_URL + "slack/update_trigger",
    deleteTrigger: API_GATEWAY_URL + "slack/delete_trigger",
    getQRCode: API_GATEWAY_URL + "profile/get_qrcode",
    updateProfileImage: API_GATEWAY_URL + "profile/update_image",
    getVCUnsubscribers: API_GATEWAY_URL + "contact/get_all_unsubscribed",
    UnsubscribeVipeCloudContacts: API_GATEWAY_URL + "contact/unsubscribe_vipecloud_contacts",
    getSearchedContacts: API_GATEWAY_URL + "contact/list_all_globally",
    moveSelectedContacts: API_GATEWAY_URL + "contact/move_contacts_to_group",
    UpdateMsgSid: API_GATEWAY_URL + "admin/change_messaging_sid",
    createCampaignV3: API_GATEWAY_URL + "campaign/create_campaign_v3",
    UpdateReadStatus: API_GATEWAY_URL + "contact/set_im_read_status",
    GetAdminDropdownGroupsList: API_GATEWAY_URL + "admin/dropdown/group/list_all",
    AddForeignContact: API_GATEWAY_URL + "admin/add_contact",
    UpdateOmgState: API_GATEWAY_URL + "admin/update_omega_customer_state",
    GetSearchSentCampaigns: API_GATEWAY_URL + "campaign/serch_get_all_sent",
    getAllDnc: API_GATEWAY_URL + "contact/list_all_dnc",
    createCampaignVS: API_GATEWAY_URL + "campaign/create_campaign_shorten",
    getShortLinkSettingDetails: API_GATEWAY_URL + "contact/get_shortlink_settings",
    updateShortLinkSetting: API_GATEWAY_URL + "contact/update_shortlink_settings",
    getAllShortlinks: API_GATEWAY_URL + "contact/get_all_shortlinks",
    getShortlinkAnalytics: API_GATEWAY_URL + "contact/get_shortlink_analytics",
    DownloadlinkAnalytics: API_GATEWAY_URL + "contact/download_link_analytics",

    validateCampaignFunds: API_GATEWAY_URL + "campaign/validate_funds_before_send",
    StopAllSubscribers: API_GATEWAY_URL + "campaign/stop_campaign_all_followups",

    updateExternalFollowups: API_GATEWAY_URL + "external/update_external_followups",
    getAllFollowups: API_GATEWAY_URL + "external/get_all_followups",


}